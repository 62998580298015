import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter, inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import SelectionArea, { SelectionEvent } from '../../../assets/scripts/vendors/viselect/viselect';
import { fromEvent, Subscription } from 'rxjs';
import { StaticDependenciesService } from '@cinetixx/cinetixx-ui';

@Directive({
  selector: '[appAreaSelect]'
})
export class AreaSelectDirective implements AfterViewInit, OnDestroy {

  @Input() selectableElementsSelectors: string[];
  @Input() unselectElementsSelector: string;
  @Input() allowSelectionWhenCtrlPressed = true;
  @Input() excludedSelectorsFromDrag: string[] = [];
  @Input() boundaryClassName = this._elementRef.nativeElement.className;

  @Output() onAreaSelectStart = new EventEmitter<MouseEvent>();
  @Output() onAreaSelectEnd = new EventEmitter<MouseEvent>();
  @Output() onSelect = new EventEmitter<HTMLElement[]>();
  @Output() onMouseMove = new EventEmitter<{
    added: Element[],
    removed: Element[],
  }>();
  @Output() loadingState = new EventEmitter<boolean>();
  @Output() isMouseInContainer = new EventEmitter<boolean>();

  public selectionArea: SelectionArea;

  private _selectedElements: HTMLElement[] = [];
  private _clickSub$: Subscription;
  private _isMouseInContainer = false;
  private readonly _document = StaticDependenciesService.document;
  private readonly _renderer2 = StaticDependenciesService.renderer;
  private readonly _subs$: Subscription[] = [];

  public constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
  ) {}

  public ngAfterViewInit(): void {
    this.initSelectionArea();
  }

  public select(query: readonly (string | Element)[] | string | Element, quiet?: boolean): Element[] {
    const elements = this.selectionArea.select(query, quiet);
    this._selectedElements = this.selectionArea.getSelection() as HTMLElement[];

    return elements;
  }

  public deselect(el: Element, quiet?: boolean): boolean {
    const isRemoved = this.selectionArea.deselect(el, quiet);
    this._selectedElements = this.selectionArea.getSelection() as HTMLElement[];

    return isRemoved;
  }

  private initSelectionArea(): void {
    this.selectionArea = new SelectionArea({
      selectables: this.selectableElementsSelectors.map(x => `.${ this.boundaryClassName } .${ x }`),
      boundaries: [`.${ this.boundaryClassName }`]
    });

    this._renderer2.addClass(this._elementRef.nativeElement, 'user-select-none');

    this.selectionArea.on('beforestart', this.onBeforeStart.bind(this));
    this.selectionArea.on('start', this.onStart.bind(this));
    this.selectionArea.on('move', this.onMove.bind(this));
    this.selectionArea.on('stop', this.onStop.bind(this));

    this.initClick();

    this._subs$.push(
      fromEvent<KeyboardEvent>(this._document, 'keydown').subscribe(this.onKeydown.bind(this)),
      fromEvent<MouseEvent>(this._elementRef.nativeElement, 'mouseenter').subscribe(() => {
        this._isMouseInContainer = true;
        this.isMouseInContainer.emit(this._isMouseInContainer);
      }),
      fromEvent<MouseEvent>(this._elementRef.nativeElement, 'mouseleave').subscribe(() => {
        this._isMouseInContainer = false;
        this.isMouseInContainer.emit(this._isMouseInContainer);
      }),
    );
  }

  private onKeydown(event: KeyboardEvent): void {
    if (this._isMouseInContainer && (event.ctrlKey || event.metaKey) && event.key === 'a') {
      event.preventDefault();
      this.loadingState.emit(true);
      this._clickSub$?.unsubscribe();

      setTimeout(() => {
        this.selectionArea.select(this.selectableElementsSelectors.map(x => `.${ this.boundaryClassName } .${ x }`));
        this._selectedElements = this.selectionArea.getSelection() as HTMLElement[];
        this.onSelect.emit(this._selectedElements);
        this.loadingState.emit(false);
        setTimeout(this.initClick.bind(this));
      });
    }
  }

  private onBeforeStart({ event }: SelectionEvent): boolean {
    if (
      (this.allowSelectionWhenCtrlPressed ? false : ((event as MouseEvent).ctrlKey || (event as MouseEvent).metaKey))
      || event.which === 3
      || event.which === 2
    ) {
      return false;
    } else {
      return !this.excludedSelectorsFromDrag.some(selector =>
        (event.target as HTMLElement).closest(selector) || (event.target as HTMLElement).matches(selector)
      );
    }
  }

  private onStart({ event, selection, store }: SelectionEvent): void {
    if (!(event as MouseEvent).ctrlKey && !(event as MouseEvent).metaKey) {
      for (let i = 0; i < store.stored.length; i++) {
        this._renderer2.removeClass(store.stored[i], 'selected');
      }

      selection.clearSelection();
    }

    this.onAreaSelectStart.emit(event as MouseEvent);
  }

  private onMove({ store: { changed: { added, removed } } }: SelectionEvent): void {
    for (let i = 0; i < added.length; i++) {
      this._renderer2.addClass(added[i], 'selected');
    }

    for (let i = 0; i < removed.length; i++) {
      this._renderer2.removeClass(removed[i], 'selected');
    }

    if (added.length !== 0 || removed.length !== 0) {
      this.onMouseMove.emit({
        added,
        removed,
      });
    }
  }

  private onStop({ event, store: { stored, selected } }: SelectionEvent): void {
    this._selectedElements = [...Array.from(new Set([...selected, ...stored]))] as HTMLElement[];

    this.onAreaSelectEnd.emit(event as MouseEvent);
    this.onSelect.emit(this._selectedElements);
  }

  private onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (target !== this._document.activeElement) {
      (this._document.activeElement as HTMLInputElement).blur();
    }

    if (target.classList.contains(this.unselectElementsSelector) && this._selectedElements.length > 0) {
      this.loadingState.emit(true);

      setTimeout(() => {
        for (let i = 0; i < this._selectedElements.length; i++) {
          this.selectionArea.deselect(this._selectedElements[i]);
        }

        this._selectedElements = [];
        this.onSelect.emit(this._selectedElements);
        this.loadingState.emit(false);
      });
    }
  }

  private initClick(): void {
    this._clickSub$ = fromEvent<MouseEvent>(this._elementRef.nativeElement, 'click').subscribe(this.onClick.bind(this));
  }

  public ngOnDestroy(): void {
    this.selectionArea.unbindAllListeners();
    this._clickSub$?.unsubscribe();
    this._subs$.forEach(sub => sub.unsubscribe());
    this.selectionArea.destroy();
  }
}
