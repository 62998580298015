export enum Dictionaries {
  AudioTypes = 'AudioTypes',
  Languages = 'Languages',
  MovieVersionTypes = 'MovieVersionTypes',
  AgeRatings = 'AgeRatings',
  Topics = 'Topics',
  MovieTypes = 'MovieTypes',
  Countries = 'Countries',
  CastTypes = 'CastTypes',
  Roles = 'Roles',
  VideoTypes = 'VideoTypes',
  SalesTax = 'SalesTax',
  ShowTypes = 'ShowTypes',
  CountriesMovie = 'CountriesMovie',
  KioskActionsTypes = 'KioskActionsTypes',
  TerminalTypes = 'TerminalTypes',
  KioskLayoutTypes = 'KioskLayoutTypes',
  SeatTypes = 'SeatTypes',
  DefaultSeatStatuses = 'DefaultSeatStatuses',
  Distributors = 'Distributors',
  AuditoriumTypes = 'AuditoriumTypes',
  MandatorFirms = 'MandatorFirms',
  PhoneTypes = 'PhoneTypes',
  Cities = 'Cities',
}
